import React from 'react'

export default function TeamSection() {
    return (
        <div>
            <div className='text-sidatsGreen text-4xl font-bold sm:text-left pt-20 w-[90%] mx-auto pt-8'>Our Team</div>
            <div className='text-sidatsGreen text-2xl  sm:text-left pt-20 w-[90%] mx-auto pt-8'>Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</div>

            <div className='flex justify-around flex-wrap pt-10 gap-4'>

                <div className='flex flex-row justify-center pt-10 bg-white w-[296px] h-[454px] rounded border border-sidatsGreen'>

                    <div className='flex flex-col items-center'>
                        <img src='/pictures/sidati.jpeg' className='rounded-full w-[150px] h-[150px]' />
                        <div className='text-black text-2xl font-bold pt-4 text-left'>Sidati NOUHI</div>
                        <div className='text-black text-lg font-medium pt-2'>Software Engineer</div>
                        <div className='text-black text-sm font-medium pt-2'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique unde. Inventore cumque, blanditiis harum unde perspiciatis quisquam!</div>
                        <div className='flex pt-6'>
                            <a href="">
                                <img src="/facebook-f.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                            <a href="">
                                <img src="/twitter.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>

                            <a href="">
                                <img src="/dribbble.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                            <a href="">
                                <img src="/github.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                        </div>
                    </div>

                </div>
                <div className='flex flex-row justify-center pt-10 bg-white w-[296px] h-[454px] rounded border border-sidatsGreen'>

                    <div className='flex flex-col items-center'>
                        <img src='/pictures/hassanNouhi' className='rounded-full w-[150px] h-[150px] ' />
                        <div className='text-black text-2xl font-bold pt-4 text-left'>Hassan Nouhi</div>
                        <div className='text-black text-lg font-medium pt-2'>Software Engineer</div>
                        <div className='text-black text-sm font-medium pt-2'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique unde. Inventore cumque, blanditiis harum unde perspiciatis quisquam!</div>
                        <div className='flex pt-6'>
                            <a href="">
                                <img src="/facebook-f.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                            <a href="">
                                <img src="/twitter.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>

                            <a href="https://www.linkedin.com/in/hassan-nouhi/">
                                <img src="/dribbble.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                            <a href="">
                                <img src="/github.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                        </div>
                    </div>

                </div>
                <div className='flex flex-row justify-center pt-10 bg-white w-[296px] h-[454px] rounded border border-sidatsGreen'>

                    <div className='flex flex-col items-center'>
                        <img src='/pictures/' className='rounded-full w-[150px] h-[150px]' />
                        <div className='text-black text-2xl font-bold pt-4 text-left'>Ayoub ZARI</div>
                        <div className='text-black text-lg font-medium pt-2'>Software Engineer</div>
                        <div className='text-black text-sm font-medium pt-2'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique unde. Inventore cumque, blanditiis harum unde perspiciatis quisquam!</div>
                        <div className='flex pt-6'>
                            <a href="">
                                <img src="/facebook-f.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                            <a href="">
                                <img src="/twitter.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>

                            <a href="">
                                <img src="/dribbble.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                            <a href="">
                                <img src="/github.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                        </div>
                    </div>

                </div>
                <div className='flex flex-row justify-center pt-10 bg-white w-[296px] h-[454px] rounded border border-sidatsGreen'>

                    <div className='flex flex-col items-center'>
                        <img src='/pictures/bouzhar.jpeg' className='rounded-full w-[150px] h-[150px]' />
                        <div className='text-black text-2xl font-bold pt-4 text-left'>Adallah BOUZHAR</div>
                        <div className='text-black text-lg font-medium pt-2'>Software Engineer</div>
                        <div className='text-black text-sm font-medium pt-2'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique unde. Inventore cumque, blanditiis harum unde perspiciatis quisquam!</div>
                        <div className='flex pt-6'>
                            <a href="">
                                <img src="/facebook-f.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                            <a href="">
                                <img src="/twitter.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>

                            <a href="">
                                <img src="/dribbble.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                            <a href="">
                                <img src="/github.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                        </div>
                    </div>

                </div>
                <div className='flex flex-row justify-center pt-10 bg-white w-[296px] h-[454px] rounded border border-sidatsGreen'>

                    <div className='flex flex-col items-center'>
                        <img src='/pictures/lmodir.jpeg' className='rounded-full w-[150px] h-[150px]' />
                        <div className='text-black text-2xl font-bold pt-4 text-left'>Mohammed AIT GABA</div>
                        <div className='text-black text-lg font-medium pt-2'>Software Engineer</div>
                        <div className='text-black text-sm font-medium pt-2'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique unde. Inventore cumque, blanditiis harum unde perspiciatis quisquam!</div>
                        <div className='flex pt-6'>
                            <a href="">
                                <img src="/facebook-f.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                            <a href="">
                                <img src="/twitter.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>

                            <a href="">
                                <img src="/dribbble.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                            <a href="">
                                <img src="/github.svg" alt="" className='h-[28px] w-[28px]' />
                            </a>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
