import React from 'react';

export default function Section5() {
    return (
        <div className='flex flex-col sm:flex-row justify-around w-[90%] mx-auto pt-10'>
            <div className='text-center sm:text-left'>
                <div>
                    <p className='text-3xl sm:text-5xl font-bold'>
                        What Benefit Will <br /> You Get
                    </p>
                </div>

                <div className='flex justify-start sm:justify-start pt-6 sm:pt-10'>
                    <img src="/pictures/Subtract.png" alt="Icon" />
                    <p className='pl-3 pt-1 text-sm sm:text-base'>Free Consulting With Expert Saving Money</p>
                </div>

                <div className='flex justify-start sm:justify-start pt-3'>
                    <img src="/pictures/Subtract.png" alt="Icon" />
                    <p className='pl-3 pt-1 text-sm sm:text-base'>Online Banking</p>
                </div>

                <div className='flex justify-start sm:justify-start pt-3'>
                    <img src="/pictures/Subtract.png" alt="Icon" />
                    <p className='pl-3 pt-1 text-sm sm:text-base'>Investment Report Every Month</p>
                </div>

                <div className='flex justify-start sm:justify-start pt-3'>
                    <img src="/pictures/Subtract.png" alt="Icon" />
                    <p className='pl-3 pt-1 text-sm '>Saving Money For The Future</p>
                </div>

                <div className='flex justify-start sm:justify-start pt-3'>
                    <img src="/pictures/Subtract.png" alt="Icon" />
                    <p className='pl-3 pt-1 text-sm '>Online Transaction</p>
                </div>
            </div>

            <div className='pt-6 sm:pt-0 text-center'>
                <img src="/pictures/picSection5.png" alt="pic" className='mx-auto' />
            </div>
        </div>
    );
}
