import React from 'react';

export default function Section4() {
    return (
        <>
            <div className='flex flex-col sm:flex-row justify-around gap-4 w-[90%] mx-auto pt-10'>
                <div className='text-center sm:text-left'>
                    <p className='text-3xl sm:text-4xl'>Nos <br /> fonctionalités</p>
                </div>
                <div className='text-center sm:text-left'>
                    <p className='text-sm sm:text-base'>We offer a variety of interesting features that you can <br /> help increase your productivity at work and manage <br /> your projects easily</p>
                </div>
                <div className='text-center'>
                    <button type="button" className="py-2 px-4 sm:py-2.5 sm:px-5 text-sm font-medium bg-sidatsGreen focus:outline-none rounded-full border text-white">Get Started</button>
                </div>
            </div>

            <div className='flex flex-col sm:flex-row justify-around gap-5 w-[90%] mx-auto pt-10'>
                <div className='text-center sm:text-left'>
                    <img src="/pictures/Frame 247.png" alt="Accessibilité elevé" className="mx-auto sm:mx-0" />
                    <h1 className='text-xl sm:text-2xl pt-3'>Accessibilité élevée</h1>
                    <p className='text-sm sm:text-base'>Here you can handle projects together <br /> with team virtually</p>
                </div>
                <div className='text-center sm:text-left'>
                    <img src="/pictures/Frame 53.png" alt="Data security" className="mx-auto sm:mx-0" />
                    <h1 className='text-xl sm:text-2xl pt-3'>Data security</h1>
                    <p className='text-sm sm:text-base'>No need to worry about storage <br /> because we provide storage up to 2 TB</p>
                </div>
                <div className='text-center sm:text-left'>
                    <img src="/pictures/Frame 54.png" alt="Daily Analytics" className="mx-auto sm:mx-0" />
                    <h1 className='text-xl sm:text-2xl pt-3'>Daily Analytics</h1>
                    <p className='text-sm sm:text-base'>We always provide useful information to <br /> make it easier for you every day</p>
                </div>
            </div>
        </>
    );
}
