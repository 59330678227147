import React from 'react';

export default function Section3() {
    return (
        <div className='flex flex-col sm:flex-row justify-around w-[90%] mx-auto p-10'>
            <div className='pt-9 text-center sm:text-left'>
                <div className='text-3xl sm:text-5xl font-bold'>
                    Comment nous <br /> améliorons vos processus
                </div>
                <p className='pt-6 text-sm sm:text-base'>
                    SaaS become a common delivery model for many business applications, including <br />
                    office software, messaging software, payroll processing software, <br />
                    DBMS software, management software
                </p>
            </div>

            <div className='pt-6 sm:pt-0'>
                <div className='flex flex-col gap-4 sm:flex-row p-7 items-center'>
                    <div className='flex items-center'>
                        <img src="/pictures/activity 1.png" alt="Disponibilté des données" />
                    </div>

                    <div className='pl-4 text-center sm:text-left'>
                        <div className="text-xl sm:text-2xl font-bold">Disponibilté des données</div>
                        <p className='text-sm sm:text-base'>
                            Plan, collaborate, and publish your content <br />
                            that drives meaningful engagement and growth for your brand
                        </p>
                    </div>
                </div>

                <div className='flex flex-col gap-4 sm:flex-row p-7 items-center'>
                    <div className='flex items-center'>
                        <img src="/pictures/pie-chart 1.png" alt="Analyse et rapport" />
                    </div>

                    <div className='pl-4 text-center sm:text-left'>
                        <div className="text-xl sm:text-2xl font-bold">Analyse et rapport</div>
                        <p className='text-sm sm:text-base'>Analyze your performance and create gorgeous reports</p>
                    </div>
                </div>

                <div className='flex flex-col gap-4 sm:flex-row p-7 items-center'>
                    <div className='flex items-center'>
                        <img src="/pictures/command 1.png" alt="Accessibilté" />
                    </div>

                    <div className='pl-4 text-center sm:text-left'>
                        <div className="text-xl sm:text-2xl font-bold">Accessibilté</div>
                        <p className='text-sm sm:text-base'>Quickly navigate and engage with your audience</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
