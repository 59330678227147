import React from 'react';

export default function Button() {
    return (
        <>


            <div className='pt-8' style={{ display: 'flex', flexDirection: 'row' }}>
                <button type="button" class="py-2.5 px-5 me-2 mb-2 md:text-sm text-xs font-medium bg-sidatsGreen focus:outline-none rounded-full border text-white">Essayez maintenant</button>  
                
                
                <button className='flex  md:pl-4 md:pt-1'> <img src="/pictures/Play.png" /> <p className='pt-1.5 md:pt-2'>Voir Demo</p></button>
            </div>

        </>

    );
};

