import logo from './logo.svg';
import './App.css';
import  MainPage from './components/MainPage.jsx';

function App() {
  return (
    <div className="App">
      <header >
      
      <MainPage/>

      </header>
    </div>
  );
}

export default App;
