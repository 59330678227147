import React from 'react';

const Section2 = () => {
  return (

    <>


<div className="flex flex-col md:flex-row pt-20 w-[90%] mx-auto mb-10">
  <div className="w-full md:w-1/2 pt-10 text-left">
    <p className='text-4xl md:text-6xl font-bold text-left p-2'>
      Gérez vos process <br /> audit de manière <br /> efficace
    </p>
    <p className='text-2xl md:text-3xl text-left pt-2 pb-3'>
      No need to worry about your files being lost because we <br /> are very loyal to be your storage platform.
    </p>
    <button type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium bg-sidatsGreen focus:outline-none rounded-full border text-white">
      Commencer
    </button>
  </div>

  <div className="flex justify-center w-full md:w-1/2">
    <img src="/pictures/platformPic.svg" alt="Image" />
  </div>
</div>


    </>


  );
};

export default Section2;