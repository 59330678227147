import React from 'react';
import Navbar from './navbar';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import TeamSection from './TeamSection';
import Footer from './Footer';

export default function MainPage() {

    return (


        <>
            <div>


                <div id='section1' style={{ backgroundImage: "url('/pictures/bg11.png')", backgroundSize: "cover" }}>
                    <Navbar />
                    <Section1 />
                </div>


                <div id='section2' style={{ backgroundImage: "url('/pictures/bgSection2.png')", backgroundSize: "cover", height: "44rem" }}>
                    <Section2 />
                </div>

                <div id='section3' style={{backgroundColor: "background1" }}>
                    <Section3 />
                </div>

                <div className='pb-10' id='section4' style={{backgroundImage: "url('/pictures/bgSection4.png')",backgroundSize: "cover"  }}>
                    <Section4 />
                </div>

                <div className='pb-10' id='section5' style={{backgroundImage: "url('/pictures/bgSection5.png')" ,backgroundSize: "cover"}}>
                    <Section5/>
                </div>

                <div className='pb-10' id='section5' style={{backgroundImage: "url('/pictures/bgSection5.png')" ,backgroundSize: "cover"}}>
                    <TeamSection/>
                </div>

                <div style={{backgroundColor:"#161C28"}}>
                    <Footer/>
                </div>


            </div>



        </>





    )
}
