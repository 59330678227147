import React from 'react'
import { useState } from 'react';
import Buttons from './Button';

export default function Section1() {
    return (
    
    <>
    
    <div className="flex flex-col md:flex-row justify-around items-center h-auto md:h-160 mt-10 w-[90%] mx-auto">
  <div className="w-full md:w-2/3 p-4 text-center">
    <div className="text-5xl md:text-8xl font-bold text-left">
      We’re here to <br /> Increase your <br /> audit conformity
    </div>

    <div className='w-full md:w-[680px] pt-8'>
      <img src="/pictures/Vector.png" alt="img" className="mx-auto md:mx-0" />
    </div>

    <div className='mt-5 text-xl md:text-3xl text-left'>
      Let's make your work more organized and easier using <br /> the Taskio Dashboard with many of the latest <br /> features in managing work every day.
    </div>

    <Buttons />
  </div>

  <div className="w-full md:w-auto p-4 text-center">
    <div>
      <img src="/pictures/Group.png" alt="img" className="mx-auto hidden md:block" />
    </div>
  </div>
</div>

    
    
    </>
      
    )
}
